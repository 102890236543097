// window.onload = function() {
// 	this.setTimeout(() => {
// 		$('#pageLoader').fadeOut(1000);
// 	}, 2000);
// };

const $dropdown = $('.dropdown');
const $dropdownToggle = $('.dropdown-toggle');
const $dropdownMenu = $('.dropdown-menu');
const showClass = 'show';

$(window).on('load resize', function() {
	if (this.matchMedia('(min-width: 768px)').matches) {
		$dropdown.hover(
			function() {
				const $this = $(this);
				$this.addClass(showClass);
				$this.find($dropdownToggle).attr('aria-expanded', 'true');
				$this.find($dropdownMenu).addClass(showClass);
			},

			function() {
				const $this = $(this);
				$this.removeClass(showClass);
				$this.find($dropdownToggle).attr('aria-expanded', 'false');
				$this.find($dropdownMenu).removeClass(showClass);
			}
		);
	} else {
		$dropdown.off('mouseenter mouseleave');
	}
});

$(document).ready(() => {
	this.setTimeout(() => {
		$('#pageLoader').fadeOut(1000);
	}, 3000);

	// Custom function which toggles between sticky class (is-sticky)
	var stickyToggle = function(sticky, stickyWrapper, scrollElement) {
		var stickyHeight = sticky.outerHeight();
		var stickyTop = stickyWrapper.offset().top;

		if (scrollElement.scrollTop() >= stickyTop) {
			stickyWrapper.height(stickyHeight);
			sticky.addClass('is-sticky');
		} else {
			sticky.removeClass('is-sticky');
			stickyWrapper.height('auto');
		}
	};

	// Find all data-toggle="sticky-onscroll" elements
	$('[data-toggle="sticky-onscroll"]').each(function() {
		var sticky = $(this);
		var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
		sticky.before(stickyWrapper);
		sticky.addClass('sticky');

		// Scroll & resize events
		$(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
			stickyToggle(sticky, stickyWrapper, $(this));
		});

		// On page load
		stickyToggle(sticky, stickyWrapper, $(window));
	});

	$('.search').click(() => {
		$('.search-bar').css('top', '0');
	});
	$('.close-searchBar').click(() => {
		$('.search-bar').css('top', '-100px');
	});

	function myFunction(x) {
		if (x.matches) {
			// If media query matches
			window.onscroll = function() {
				// document.querySelector('#triangle').style.backgroundPosition = "0px " + (0 + (Math.max(document.documentElement.scrollTop, document.body.scrollTop) / 35)) + "px";
				var tris = document.querySelectorAll('#triangle'),
					i,
					pos = ['0px ', 'right '];

				for (i = 0; i < tris.length; ++i) {
					tris[i].style.backgroundPosition =
						pos[i] + (0 + Math.max(document.documentElement.scrollTop, document.body.scrollTop) / 48) + 'px';
				}
			};
		} else {
			//   document.body.style.backgroundColor = "pink";
		}
	}

	var x = window.matchMedia('(min-width: 992px)');
	myFunction(x); // Call listener function at run time
	x.addListener(myFunction); // Attach listener function on state changes

	$('.dropdown-toggle').click(function(e) {
		if (window.matchMedia('(max-width: 768px)').matches) {
			e.preventDefault();
		} else {
			window.location.href = $(this).attr('href');
		}
	});

	// Testimonial SLider
	$('#testimonials-slider').owlCarousel({
		loop: true,
		items: 1,
		center: true,
		margin: 30,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 800,
		nav: false,
		dots: false,
		center: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,

		responsive: {
			0: {
				items: 1,
			},

			768: {
				items: 3,
			},
		},
	});

	// Category Slider
	$('#category-slider').owlCarousel({
		loop: true,
		items: 4,
		margin: 0,
		dots: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		autoplay: true,
		autoplayTimeout: 3000,
		smartSpeed: 800,
		responsive: {
			0: {
				items: 2,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 4,
			},
		},
	});

	// Products Slider
	$('#products-slider').owlCarousel({
		loop: true,
		items: 4,
		margin: 0,
		dots: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		autoplay: true,
		autoplayTimeout: 3000,
		smartSpeed: 800,
		rtl: true,
		responsive: {
			0: {
				items: 2,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 4,
			},
		},
	});

	// Owl Carousel Products Slider
	//
	var owlone = $('.owlOne');

	owlone.owlCarousel({
		loop: true,
		margin: 0,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 2000,
		smartSpeed: 500,
		items: 1,
		responsiveClass: true,
		responsive: {
			0: {
				items: 3,
			},

			768: {
				items: 5,
			},

			992: {
				items: 6,
			},
		},
	});

	// Go to the next item
	$('.right-slider-btn').click(function() {
		owlone.trigger('next.owl.carousel');
	});

	// Go to the previous item
	$('.left-slider-btn').click(function() {
		owlone.trigger('prev.owl.carousel');
	});

	// Blog Item Card Title Text Limiter
	$('.card-title').each(function(i) {
		len = $(this).text().length;
		if (len > 66) {
			$(this).text(
				$(this)
					.text()
					.substr(0, 67) + '...'
			);
		}
	});
	//Blog Item Card Detail Text Limiter
	$('.card-text').each(function(i) {
		len = $(this).text().length;
		if (len > 214) {
			$(this).text(
				$(this)
					.text()
					.substr(0, 215) + '...'
			);
		}
	});

	// Load More Blog Items

	$('.bi').hide();
	$('.bi')
		.slice(0, 6)
		.show();
	$('.loadMoreBlog').on('click', function(e) {
		e.preventDefault();
		// Assign the result of the media query to a variable

		// Check if the media query is a match

		var x = window.matchMedia('(max-width: 768px)');
		if (x.matches) {
			$('.bi:hidden')
				.slice(0, 2)
				.slideDown(1000);
		} else {
			$('.bi:hidden')
				.slice(0, 3)
				.slideDown(1000);
		}

		if ($('.bi:hidden').length == 0) {
			$('.loadMoreBlog').text('No More Content');
		}
	});

	$('.clients').owlCarousel({
		loop: true,
		margin: 0,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 3000,
		smartSpeed: 1000,
		items: 4,
		responsiveClass: true,
		responsive: {
			0: {
				items: 4,
			},
			992: {
				items: 6,
			},
		},
	});
});

function openTab(evt, tabName) {
	var i, tabcontent, tablinks;
	tabcontent = document.getElementsByClassName('tabcontent');
	for (i = 0; i < tabcontent.length; i++) {
		tabcontent[i].style.display = 'none';
	}
	tablinks = document.getElementsByClassName('tablinks');
	for (i = 0; i < tablinks.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(' active', '');
	}
	document.getElementById(tabName).style.display = 'block';
	evt.currentTarget.className += ' active';
}
